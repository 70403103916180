import { DARK_GRAY, LIGHT_GRAY, MEDIUM_GRAY, WHITE } from 'src/constants/colors';
import {
    FONT_SIZE_PHONE,
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';

import Clock from 'src/components/header/Clock';
import HeaderButton from 'src/components/buttons/HeaderButton';
import React from 'react';
import Scene from 'src/constants/scene';
import nornet from 'src/img/nornet.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

/**
 * @description header style css
 */
const HeaderStyled = styled.div`
    background: ${WHITE};
    border: solid;
    border-color: ${MEDIUM_GRAY};
    border-width: 0px 0px 1px 0px;
    color: ${MEDIUM_GRAY};
    display: flex;
    padding: 0.2em 1em 0em 1em;
`;
/**
 * @descrition Image styled CSS
 */
const Img = styled.img`
    background: ${LIGHT_GRAY};
    border: solid;
    border-color: ${MEDIUM_GRAY};
    border-radius: 10px;
    border-width: 1px;
    margin: 0em 1em 0em 0em;
    object-fit: contain;
    padding: 0.5em;

    &:hover {
        border-color: ${DARK_GRAY};
        background: ${MEDIUM_GRAY};
    }

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        height: 0.5em;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        height: 0.8em;
    }

    @media (min-width: ${MAX_WIDTH_PHONE_LANDSCAPE + 1}px) {
        height: 2em;
    }
`;
/**
 * @description end of header style CSS
 */
const HeaderEnd = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;
const HeaderStart = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;
/**
 * @description header text style CSS
 */
const HeaderText = styled.h1`
    margin: 0;
    font-weight: 300;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        font-size: ${FONT_SIZE_PHONE}em;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        font-size: ${FONT_SIZE_PHONE}em;
    }
`;
interface Props {
    /**
     * @description current scene
     * @type {Scene}
     */
    scene: Scene;
}
/**
 * @description header functional component for the HTML page
 * @type {React.FC}
 */
const Header: React.FC<Props> = ({ scene }) => {
    const navigate = useNavigate();
    return (
        <HeaderStyled>
            <HeaderStart>
                <Img
                    src={nornet}
                    alt="Nornet logo"
                    onClick={() => window.open('https://www.nntb.no/')}
                ></Img>
                <HeaderText>NorNet Edge</HeaderText>
            </HeaderStart>

            <HeaderEnd>
                <HeaderButton
                    text={'Map'}
                    onClick={() => {
                        navigate('/map');
                    }}
                    isSelected={scene == Scene.MAP}
                />
                <HeaderButton
                    text={'Statistics'}
                    onClick={() => {
                        navigate('/statistics');
                    }}
                    isSelected={scene == Scene.STATISTICS}
                />

                <HeaderButton
                    text={'Comparative'}
                    onClick={() => {
                        navigate('/comparative');
                    }}
                    isSelected={scene == Scene.COMPARATIVE}
                />
                <Clock />
            </HeaderEnd>
        </HeaderStyled>
    );
};

export default Header;
